<template>
  <div>
    <v-banner>
      KELOLA UNIT KERJA
      <template v-slot:actions>
        <v-btn color="primary" text @click="add()"> Tambah Unit Kerja </v-btn>
      </template>
    </v-banner>

    <div class="mt-5">
      <CSlider />
    </div>

    <v-divider class="my-3"></v-divider>

    <div class="mt-5">
      <v-card class="px-2">
        <v-card-title class="subtitle-2">
          <v-icon left color="primary">mdi-chart-box</v-icon>
          Kategori : {{ $route.params.id }}
          <v-spacer></v-spacer>
          <v-btn icon color="error" @click="closeDetail()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-data-table :headers="headers" :items="data" :items-per-page="5">
          <template v-slot:[`item.nomor`]="{ item }">
            {{
              data
                .map(function (x) {
                  return x.id;
                })
                .indexOf(item.id) + 1
            }}
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon small class="mr-1" @click="viewItem(item)">
              mdi-archive
            </v-icon>
            <v-icon small class="mr-1" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import CSlider from "@/components/dashboard/unitkerja/slider";

export default {
  components: {
    CSlider,
  },

  computed: {},

  data: () => ({
    headers: [
      {
        text: "Nomor",
        value: "nomor",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "Nama Unit Kerja", align: "start", value: "nama_uk" },
      { text: "Induk Unit Kerja", align: "start", value: "induk_uk" },
      { text: "Alamat", align: "start", value: "alamat" },
      { text: "Nama Pimpinan", align: "start", value: "pimpinan" },
      { text: "Action", value: "action", width: "100px" },
    ],

    data: [
      {
        id: 1,
        nama_uk: "SDN 01 Paguat",
        induk_uk: "Dinas Pendidikan",
        alamat: "Jl. Tragis, Paguat",
        pimpinan: "Jokowi",
      },
      {
        id: 2,
        nama_uk: "SDN 02 Paguat",
        induk_uk: "Dinas Pendidikan",
        alamat: "Jl. Tragis, Paguat",
        pimpinan: "Prabowo",
      },
      {
        id: 3,
        nama_uk: "SDN 03 Paguat",
        induk_uk: "Dinas Pendidikan",
        alamat: "Jl. Tragis, Paguat",
        pimpinan: "Megawati",
      },
      {
        id: 4,
        nama_uk: "SDN 04 Paguat",
        induk_uk: "Dinas Pendidikan",
        alamat: "Jl. Tragis, Paguat",
        pimpinan: "Wiranto",
      },
      {
        id: 5,
        nama_uk: "SDN 05 Paguat",
        induk_uk: "Dinas Pendidikan",
        alamat: "Jl. Tragis, Paguat",
        pimpinan: "Surya Palo",
      },
    ],
  }),

  mounted() {},

  methods: {
    add() {
      this.$router.push("/unitkerja/add").catch(() => {});
    },

    closeDetail() {
      this.$router.push("/unitkerja").catch(() => {});
    },

    viewItem(value) {
      this.$router.push("/unitkerja/detail/" + value.id).catch(() => {});
    },
  },
};
</script>