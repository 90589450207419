<template>
  <div>
    <v-slide-group active-class="success" show-arrows>
      <v-slide-item v-for="item in items" :key="item.id">
        <v-card class="ma-4">
          <v-card-text>
            <span class="caption font-weight-black primary--text">{{
              item.label
            }}</span>
            <v-row class="mt-1">
              <v-col cols="12" md="6">
                <v-icon size="60" color="green">{{ item.icon }}</v-icon>
              </v-col>
              <v-col cols="12" md="6" class="my-auto">
                <h1>10</h1>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="py-3 mt-n3">
            <v-btn
              color="primary"
              small
              rounded
              block
              depressed
              @click="detail(item)"
            >
              Detail
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-slide-item>
    </v-slide-group>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        id: 1,
        name: "sekretariat",
        label: "Sekretariat",
        icon: "mdi-folder-home",
        to: "/unitkerja/sekretariat",
      },
      {
        id: 2,
        name: "badan",
        label: "Badan",
        icon: "mdi-folder-home",
        to: "/unitkerja/badan",
      },
      {
        id: 3,
        name: "dinas",
        label: "Dinas",
        icon: "mdi-folder-home",
        to: "/unitkerja/dinas",
      },
      {
        id: 4,
        name: "rumahsakit",
        label: "Rumah Sakit",
        icon: "mdi-folder-home",
        to: "/unitkerja/rumahsakit",
      },
      {
        id: 5,
        name: "kantorcamat",
        label: "Kantor Camat",
        icon: "mdi-folder-home",
        to: "/unitkerja/kantorcamat",
      },
      {
        id: 6,
        name: "kantorlurah",
        label: "Kantor Lurah",
        icon: "mdi-folder-home",
        to: "/unitkerja/kantorlurah",
      },
      {
        id: 7,
        name: "uptd",
        label: "UPTD",
        icon: "mdi-folder-home",
        to: "/unitkerja/uptd",
      },
      {
        id: 8,
        name: "sekolah",
        label: "Sekolah",
        icon: "mdi-folder-home",
        to: "/unitkerja/sekolah",
      },
      {
        id: 9,
        name: "puskesmas",
        label: "Puskesmas",
        icon: "mdi-folder-home",
        to: "/unitkerja/puskesmas",
      },
      {
        id: 10,
        name: "unitpos",
        label: "Unit Pos",
        icon: "mdi-folder-home",
        to: "/unitkerja/unitpos",
      },
    ],
  }),

  methods: {
    detail(value) {
      this.$router.push("/unitkerja/kategori/" + value.name).catch(() => {});
    },
  },
};
</script>